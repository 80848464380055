/* eslint-disable no-plusplus */
import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Twitter from './Twitter';
import Facebook from './Facebook';

const SEO = ({
  title = null,
  description = null,
  pathname = null,
  article = false,
  showSchema = false,
  breadcrumbs = null,
  socialImage = '/logo.png',
}) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, twitterUsername, facebookAppID },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${socialImage}`,
        siteUrl: `${siteUrl}${pathname || '/'}`,
      };

      const breadcrumbsArray = breadcrumbs && [];
      let breadcrumbPosition = 1;

      if (breadcrumbs) {
        breadcrumbs.forEach((element) => {
          const url = element.slug ? `${siteUrl}/${element.slug}/` : `${siteUrl}/`;
          const breadcrumbRepresentation = {
            '@type': 'ListItem',
            position: breadcrumbPosition,
            name: element.title,
            item: url,
          };
          breadcrumbsArray.push(JSON.stringify(breadcrumbRepresentation));
          breadcrumbPosition++;
        });
      }
      const google_site_verification = process.env.GOOGLE_SITE_VERIFICATION;
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="google-site-verification" content={google_site_verification} />
            <html lang="th" />
            {showSchema && (
              <script type="application/ld+json">
                {`
                  {
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "@id":"#website",
                    "url":"${siteUrl}${pathname || '/'}",
                    "name": "${seo.title}",
                    "potentialAction": {
                      "@type":"SearchAction",
                      "target":"https:\\/\\/www1.sbobettm.com\\/?s={search_term_string}",
                      "query-input": "required name=search_term_string"}
                  }
                `}
              </script>
            )}
            {breadcrumbsArray && (
              <script type="application/ld+json">
                {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${breadcrumbsArray}]
            }
            `}
              </script>
            )}
          </Helmet>
          <Facebook
            pageUrl={seo.siteUrl}
            type={article ? 'article' : null}
            title={seo.title}
            description={seo.description}
            image={seo.image}
            appID={facebookAppID}
          />
          <Twitter username={twitterUsername} title={seo.title} description={seo.description} image={seo.image} />
        </>
      );
    }}
  />
);

export default SEO;
