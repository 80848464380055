import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../shared/ButtonLink';
import { color, wrapper, breakpoints, sizing, border, area } from '../../utils/style';
import news from '../../img/news.svg';
import { getFile } from '../../utils/system';

const Bg = styled('div')`
  position: relative;
  padding: ${area.blockPadding};
`;

const ImgWrap = styled('div')`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
  min-height: 139px;

  .gatsby-image-wrapper {
    display: flex;
    width: 100% !important;
    height: 100%;
    border-radius: ${border.borderRadius4};

    img {
      margin: 0;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    display: block;
    padding: 0;
    margin-bottom: 10px;
    width: auto;
    max-width: 100%;
  }
`;

const NewsWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  grid-auto-rows: max-content;
  grid-row-gap: 40px;
  max-width: 100%;
  padding-bottom: 26px;

  @media (max-width: ${breakpoints.tl}) {
    grid-template-columns: repeat(3, calc(100% / 3 - 10px));
    grid-auto-rows: max-content;
  }
  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, calc(50% - 7px));
    grid-auto-rows: max-content;
  }
  @media (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(1, 100%);
    grid-auto-rows: auto;
  }

  @media (max-width: ${breakpoints.md}) and (orientation: portrait) {
    a div {
      :nth-of-type(1) {
        height: 120px;
        font-size: 13px;
        border-radius: 4px;
      }

      :nth-of-type(3) {
        max-height: 99px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 45px;
      }
      a {
        margin: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) and (min-width: ${breakpoints.sm}) and (orientation: portrait) {
    //display: flex;
    //flex-flow: wrap;
    > a {
      //width: calc(50% - 20px);
      //margin: 15px 10px;
      a {
        //margin-top: 20px;
      }
      div {
        :nth-of-type(1) {
          max-height: 176px;
          font-size: 13px;
        }

        :nth-of-type(3) {
          max-height: 92px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      :nth-of-type(3n) {
        margin-right: 0;
      }

      a {
        width: 100%;

        div a {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
    a div {
      :nth-of-type(1) {
        font-size: 13px;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      :nth-of-type(3) {
        max-height: 93px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 45px;
      }
      a {
        margin-top: 20px;
      }
    }
  }
`;

const NewsItem = styled('a')`
  @media (max-width: ${breakpoints.md}) {
  }
`;

const Wrapper = styled('div')`
  padding-bottom: 60px;
  ${wrapper}

  .headerWrapper {
    display: inline-flex;
    position: relative;

    img{
      width: 40px;
      position: absolute;
      top: 5px;
      @media (max-width: ${breakpoints.md}) {
        margin-bottom: 0;
      }
    }
    h3 {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 26px;
      font-weight: bold;
      color: ${color.lightningYellow};
      margin: .3em 0 30px 50px;
      
      @media (max-width: ${breakpoints.tl}) {
        font-size: 22px;
        margin: .4em 0 0 50px;
      }
    }
  }

  .h4 {
    padding-top: 13px;
    display: block;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 16px;
    color: ${color.lightningYellow};
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    height: 80px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: normal;
    color: ${color.alabaster};
  }
  .button {
    width: max-content;
    font-size: 14px;
    display: flex;
    
    & > svg {
      margin-left: 10px;
    }
    
    &:hover {
      & span {
        color: ${color.anakiwa};
      }
      
      & > svg {
        & path {
          fill: ${color.anakiwa}!important;
        }
      }
    }
    
    &:active {
      & span {
        color: ${color.allports};
      }
      
      & > svg {
        & path {
          fill: ${color.allports}!important;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 30px;
  }
  
  & .headerWrapper {
    & > h3 {
      margin-bottom: 24px;
    }
  }

  .recent-excerpt {
     div,
     p {
        display: inline;
     }
     
     .button {
        padding: 0;
        background: transparent;
        text-align: left;
        line-height: 1.3;
        color: ${color.blue};
        font-weight: ${sizing.normalWeight};
        font-size: inherit;
        text-decoration: underline;
        &:active,
        &:focus,
        &:hover {
          color: ${color.lightGreen};
          background: none;
        }
     }
  }
  }
`;

// REFACTOR
// Spread. I'm sure here more things.
const NewsComponent = ({ data }) => (
  <>
    {data?.map((item) => {
      const { id, node: { slug, featured_media = {}, title, content } = {} } = item;

      return (
        <NewsItem key={slug + id} href={`/${slug}/`}>
          {getFile(featured_media, 'fixed') && (
            <ImgWrap>
              <Img fixed={getFile(featured_media, 'fixed')} alt={featured_media.alt_text || 'alt'} />
            </ImgWrap>
          )}
          <span className="h4" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="recent-excerpt">
            <div>
              {content && content.substr(0, 270).replace(/(<([^>]+)>)/gi, '')}
              ...
            </div>
          </div>
        </NewsItem>
      );
    })}
  </>
);

const RecentPosts = ({ posts = null }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "ข่าวฟุตบอล" } } }, status: { eq: "publish" } }
          sort: { fields: id, order: DESC }
          skip: 0
          limit: 6
        ) {
          edges {
            node {
              slug
              title
              excerpt
              content
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fixed(width: 386, height: 176) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        placeholder: file(relativePath: { eq: "placeholder.png" }) {
          childImageSharp {
            fluid(maxWidth: 430) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const { allWordpressPost: { edges = {} } = {} } = data;

      return (
        <Bg id="recent-posts">
          <Wrapper>
            <div className="headerWrapper">
              <img className="iconImage" src={news} alt="news" />
              <h3>บทความ</h3>
            </div>
            <NewsWrapper>
              <NewsComponent data={posts || edges} />
            </NewsWrapper>

            <ButtonLink
              to="football-news"
              text="อ่านเพิ่มเติม"
              ariaText="อ่านเพิ่มเติม"
              className="button color-yellow withoutBgr"
              iconColor={color.lightningYellow}
              iconSize="15"
            />
          </Wrapper>
        </Bg>
      );
    }}
  />
);

export default RecentPosts;
