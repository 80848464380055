import React from 'react';
import { Helmet } from 'react-helmet';

// Small Refactor
// Why here default values null if checking for bool? If it's gonna be no prop - it gonna be undefined so false. Same.
export default ({ pageUrl = null, type = null, title = null, description = null, image = null, appID = null }) => (
  <Helmet defer={false}>
    {pageUrl && <meta property="og:url" content={pageUrl} />}
    {type && <meta property="og:type" content={type} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
    {appID && <meta property="fb:app_id" content={appID} />}
  </Helmet>
);
